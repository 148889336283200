<template>
  <GMapMarker
    :position="stop"
    :clickable="true"
    @click="changeOpen()"
    ref="marker_ref"
    :icon="{
      path: markerIcon,
      size: {width: 30, height: 45, f: 'px', b: 'px',},
      scale: 0.4,
      fillColor: 'blue',
      fillOpacity: 0.7,
    }"
  >
    <GMapInfoWindow v-if="isOpen">
      <table>
        <tr><th>Stop Name</th><td>:</td><td>{{stop.stop_name}}</td></tr>
        <tr><th>Stop Code</th><td>:</td><td>{{stop.stop_code}}</td></tr>
      </table>
    </GMapInfoWindow>
</GMapMarker>
</template>
<script>
  import VueGoogleMaps from '@fawmi/vue-google-maps'
  export default {
    computed: {
        google: VueGoogleMaps,
    },
    data(){
      return {
        is_open: false,
        infowindow: null,
        markerIcon: "M1.6 1.6c-2.3 2.3-2.3 31.5 0 33.8s31.5 2.3 33.8 0 2.3-31.5 0-33.8S3.9-.7 1.6 1.6zm25.3 1.9c2.2 2.2 2.8 17.2 1 21.5-1.6 3.5-2.9 3.8-3.6 1-.4-1.7-1.4-2-5.8-2-4.6 0-5.3.3-5.9 2.2l-.6 2.3-2-2.5c-1.6-2-2-4.1-2-10.5 0-9 1.3-12.6 5-13.7s12.2-.1 13.9 1.7z M13 4c0 .5 2.5 1 5.5 1S24 4.5 24 4c0-.6-2.5-1-5.5-1S13 3.4 13 4zm-2.3 2.6c-.4.4-.7 2.2-.7 4.1V14h17.1l-.3-3.8-.3-3.7-7.5-.3c-4.2-.1-7.9 0-8.3.4zm-.7 13c0 1.7 2.2 1.4 2.8-.4.2-.7-.3-1.2-1.2-1.2s-1.6.7-1.6 1.6zm14.7-1c-.8.8.1 2.4 1.4 2.4.5 0 .9-.7.9-1.5 0-1.5-1.2-2-2.3-.9z"
      }
    },
    methods:{
      changeOpen(){
        this.is_open = !this.is_open
      }
    },
    props:['stop', 'gmap', 'route', 'isOpen'],
    watch:{
      is_open(){
        const self = this
        this.gmap.$mapPromise.then(() => {
          this.$nextTick().then(() => {
            if(self.infowindow === null){
              self.infowindow = new window.google.maps.InfoWindow({
                content: '<table>' +
                    '<tr><th>Stop Name</th><td>:</td><td>'+self.stop.stop_name+'</td></tr>' +
                    '<tr><th>Stop Code</th><td>:</td><td>'+self.stop.stop_code+'</td></tr>' +
                  '</table>',
                maxWidth: 300,
              })
            }
            self.infowindow.open({
              map: self.gmap.$mapObject,
              anchor: self.$refs.marker_ref.$markerObject
            })
          })
        })
      }
    }
  }
</script>
