<template>
  <div class="form-group col-sm-12">
    <label for="pic_url">{{label}}</label>
    <div class="input-group">
      <div class="custom-file">
        <input type="file" class="custom-file-input" id="pic_url" v-on:change="handleImage($event)" accept="image/jpeg, image/png">
        <label class="custom-file-label" for="pic_url">{{this.imageName !== '' ? this.imageName : 'Choose file'}}</label>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data(){
      return {
        image: null,
        imageName: '',
      }
    },
    props:['label'],
    methods: {
      handleImage(event){
        const selectedImage = event.target.files[0]
        if(!selectedImage){
          this.image = null
          this.imageName = ''
          return
        }
        this.imageName = selectedImage.name
        this.createBase64Image(selectedImage)
      },
      createBase64Image(fileObject){
        const reader = new FileReader()
        reader.onload = (e) => {
          this.image = e.target.result
        }
        reader.readAsDataURL(fileObject)
      },
      clearImage(){
        this.image = null
        this.imageName = ''
      },
    },
    watch: {
      image(next, prev){
        if(next !== prev && next !== null) {
          this.$emit('selected-image', next)
        }
      },
    }
  }
</script>
