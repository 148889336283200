<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="account_id">Account:</label>
        <Select2 name="account_id" required v-model.number="ceiba_device.account_id" :options="accountOptions"  @change="onChange($event)" @select="onChange($event)"/>
      </div>
      <div class="form-group col-sm-12">
        <label for="vehicle_id">Vehicle:</label>
        <Select2 name="vehicle_id" v-model.number="ceiba_device.vehicle_id" :options="vehicleOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="ceiba_server_id">Ceiba Server:</label>
        <Select2 name="ceiba_server_id" required v-model.number="ceiba_device.ceiba_server_id" :options="ceibaServerOptions"/>
      </div>
      <div class="form-group col-sm-6 required">
        <label for="terminal_id">Terminal ID:</label>
        <input class="form-control" name="terminal_id" required type="text" v-model="ceiba_device.terminal_id">
      </div>
      <div class="form-group col-sm-6 required">
        <label for="channel">Channel:</label>
        <input class="form-control" name="channel" required type="number" v-model.number="ceiba_device.channel" min="0">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/ceiba_devices">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('ceiba_servers', ['ceiba_servers']),
        ...mapState('ceiba_devices', ['onRequest']),
    },
    data(){
      return {
        ceiba_device: {
          account_id: '',
          ceiba_server_id: '',
          vehicle_id: null,
          terminal_id: null,
          channel: null,
        },
        ceibaServerOptions: [],
        vehicleOptions: [],
        accountOptions: [],
      }
    },
    components:{
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.add(this.ceiba_device)
      },
      ...mapActions('ceiba_devices', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicles', ['get_vehicle_by_account']),
      ...mapActions('ceiba_servers', {getCeibaServers: 'get_all'}),
      onChange(event){
        this.get_vehicle_by_account({account_id: parseInt(event.id)})
        this.ceiba_device.vehicle_id = null
      },
    },
    created() {
      this.$emit('onChildInit', 'Add New Ceiba Device')
      this.getCeibaServers()
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      ceiba_servers(nextState, prevState){
        if(nextState !== prevState){
          this.ceibaServerOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.ceibaServerOptions.push({id:item.id, text:item.server_name})
            })
          }
          return
        }
      }
    }
  }
</script>
