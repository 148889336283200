<template>
	<div class="row justify-content-md-center">
		<div class="col-xl-2 col-sm-3 col-12">
			<div class="card bg-dark text-white rounded-lg">
				<div class="card-content">
					<div class="card-body">
						<div class="media d-flex justify-start">
							<div class="align-self-center w-50">
								<Icon icon="mdi:car-multiple" width="60%" />
							</div>
							<div class="media-body text-right align-self-center pr-2">
								<h2 class="strong">{{ totalVehicle }}</h2>
								<span>Total Unit</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-for="(dbstatus, index) in datastatus"
			:key="index"
			class="col-xl-2 col-sm-6 col-12"
		>
			<div class="card bg-dark text-white rounded-lg">
				<div class="card-content">
					<div class="card-body">
						<div class="media d-flex justify-start">
							<div class="align-self-center w-50">
								<Icon
									:icon="dbstatus.icon"
									width="60%"
									:style="{ color: dbstatus.color }"
								/>
							</div>
							<div class="media-body text-right align-self-center pr-2">
								<h2 class="strong">{{ dbstatus.data }}</h2>
								<span>{{ dbstatus.label }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Icon } from "@iconify/vue";
	export default {
		props: ["datastatus"],
		computed: {
			totalVehicle() {
				if (this.datastatus) {
					return this.datastatus.reduce((acc, item) => acc + item.data, 0);
				}
				return 0;
			},
		},
		components: {
			Icon,
		},
	};
</script>
